// import winnie from '../../image/winnie.jpg'
import testimony1 from '../../image/testimony1.jpeg'
import testimony2 from '../../image/testimony2.jpeg'
import testimony3 from '../../image/testimony3.jpeg'
import testimony4 from '../../image/testimony4.jpeg'
import testimony5 from '../../image/testimony5.jpeg'

const testimonyData = [
    {
        id: 1,
        name: 'High Hope Montessori School - Owner',
        testimony: 'I collaborated with Genesis Employment, and they provided invaluable support to High Hope Montessori School by effectively managing staff, disseminating information, offering conflict resolution expertise, contributing to policy development, and providing guidance on coaching leadership and terminations. ',
        testimony2: '',
        image: testimony1,
    },
    {
        id: 2,
        name: 'Gospel',
        testimony: 'From the moment I reached out for help, I was met with professionalism, expertise, and a genuine commitment to my success. They took the time to understand my career goals, strengths, and areas of expertise. With their guidance, my resume transformed into a powerful document that effectively showcased my skills and experiences in the best light possible. They provided tailored advice and insights that were specifically catered to my industry and the positions I was targeting.',
        testimony2: 'Their support has been invaluable, and I cannot recommend them highly enough to anyone seeking professional guidance in their career endeavors.',
        image: testimony2,
    },
    {
        id: 3,
        name: 'Folake',
        testimony: 'My experience working with Genesis employment solution was great! The business went about researching on my need in an exceptional way. Genesis employment solutions provided me with a list of employment options after carefully reviewing those that were best fit. ',
        testimony2: 'It goes without saying, I will absolutely recommend Genesis!',
        image: testimony3,
    },
    {
        id: 4,
        name: 'Princess',
        testimony: "I worked with Joy when I first moved to Canada. I was seeking a new job in marketing and had observed Joy's growth into a renowned HR professional. I enlisted her services during my job hunt. What amazed me about her service was her passion and investment in the process, one would think she was actually looking for a job for herself. ",
        testimony2: 'Aside from referring me to hiring agencies, we had several meetings and calls to modify my CV to meet Canadian standards. She brings her wealth of experience into her work in human resources and is very detailed in her feedback. Joy was instrumental in helping me land my first job in Canada. I highly recommend her, as I am a testament to her great work.',
        image: testimony4,
    },
    {
        id: 5,
        name: 'Dami',
        testimony: "The first time I interacted with Joy was in December 2021 after I had just newly moved to Canada. I was actively job hunting and had expressed my concerns as a new comer to Joy. She took it upon herself to conduct frequent check ins with me to see the status of my job search. Not only did she do those check in’s, but she job searched and interview prepped with me. ",
        testimony2: ' In February 2022, Joy made a recommendation for my current job and she walked with me through the entire process to make sure I landed the job. It was so easy working with Joy as she expressed professionalism and yet stayed personable. She was and still remains a source of encouragement in my career pursuit. I thoroughly enjoy working with Joy and would recommend her services to anyone I come in contact with.',
        image: testimony5,
    }
]
export default testimonyData;