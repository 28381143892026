import React, { Component } from 'react';
import './service.css'

export class Service extends Component {
  render() {
    return (
      <div>Service</div>
    )
  }
}

export default Service